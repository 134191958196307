import { App } from 'vue'
import { formatTimeStamp } from '@/utils/data-format'

export default function registerProperties(app: App) {
  //在这里添加的函数全局都可以调用，一般以$开头
  app.config.globalProperties.$filters = {
    formatTime(value: string) {
      return formatTimeStamp(value)
    }
  }
}
