import { createApp } from 'vue'
//全局引入
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import * as ElIconList from '@element-plus/icons-vue'

import App from './App.vue'
import './servise/axios_demo'
import 'normalize.css'
import './assets/css/index.less'

import router from './router'
import store from './store'
// import myRequest from './servise'
import { setupStore } from './store'
import { globalRegister } from './global'

const app = createApp(App)

app.use(store)
app.use(ElementPlus)
setupStore() //这个得放在use（router）前面，不然会导致刷新跳notfound页面

app.use(router)

globalRegister(app)

app.mount('#app')

for (const name in ElIconList) {
  app.component(name, ElIconList[name])
}

// interface dataType {
//   data: any
//   msg: string
//   code: number
// }
// myRequest
//   .request<dataType>({
//     url: '/live/findProductList',
//     method: 'GET',
//     showLoading: true
//   })
//   .then((res) => {
//     console.log(res)
//   })
