import { createStore, Store, useStore as useVuexStore } from 'vuex'
import { IRootState } from './types'
import { IStoreType } from './types'

import login from './login/login'
import home from './main/home/home'
import { getPageListData } from '@/servise/main/home/home'

const store = createStore<IRootState>({
  state() {
    return {
      name: 'coderwhy',
      age: 18,
      entireScene: [
        { title: '坐播', value: 'gd7IbwHiyy' },
        { title: '站播', value: 'iwN5MCef8w' }
      ],
      entireCategory: [],
      entireCamera: [],
      entireLighting: [],
      entireVideo: [],
      entireProduct: [
        { title: '相机', value: 'camera' },
        { title: '灯光', value: 'lighting' }
      ]
    }
  },
  mutations: {
    changeEntireCategory(state, list) {
      state.entireCategory = list
    },
    changeEntireCamera(state, list) {
      state.entireCamera = list
    },
    changeEntireLighting(state, list) {
      state.entireLighting = list
    },
    changeEntireVideo(state, list) {
      state.entireVideo = list
    }
  },
  getters: {},
  actions: {
    async getInitiaDataAction({ commit }) {
      //1、请求类别、相机灯光数据
      const categoryResult = await getPageListData(
        '/imgGroup/findOptionsList',
        {
          optionName: 'Category'
        }
      )
      const cameraResult = await getPageListData('/imgGroup/findOptionsList', {
        optionName: 'Camera'
      })

      const lightingResult = await getPageListData(
        '/imgGroup/findLightList',
        {}
      )
      // 视频所属分类选项
      const videoResult = await getPageListData('/live/findVideoCategory', {})

      commit('changeEntireCategory', categoryResult.data)
      commit('changeEntireCamera', cameraResult.data)
      commit('changeEntireLighting', lightingResult.data)
      commit('changeEntireVideo', videoResult.data)
    }
  },
  modules: {
    login,
    home
  }
})

export function setupStore() {
  store.dispatch('login/loadLocalLogin')
  // store.dispatch('getInitiaDataAction')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store
