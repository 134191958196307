import myRequest from '@/servise'
import { IDataType } from '@/servise/types'

export function getPageListData(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

export function deletePageData(url: string, queryInfo: any) {
  return myRequest.delete<IDataType>({
    url: url,
    data: queryInfo
  })
}

export function createPageData(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

export function editPageData(url: string, editData: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: editData
  })
}

//审核通过操作
export function processPageData(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

//发货
export function sendOutGoods(url: string, params: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: params
  })
}
