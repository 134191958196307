import myRequest from '../index'
import { IAccount, ILoginResult } from './type'
import { IDataType } from '../types'

enum LoginAPI {
  accountLogin = '/adminUser/loggingIn',
  loginUserInfo = '/user/',
  userMenu = '/role/'
}
export function accountLoginRequest(account: IAccount) {
  return myRequest.post<IDataType<ILoginResult>>({
    url: LoginAPI.accountLogin,
    data: account
  })
}

export function requestUserinfoById(id: string) {
  return myRequest.get<IDataType<ILoginResult>>({
    url: LoginAPI.loginUserInfo + id,
    showLoading: false
  })
}

export function requestUserMenuById(id: string) {
  return myRequest.get<IDataType<ILoginResult>>({
    url: LoginAPI.userMenu + id + '/menu',
    showLoading: false
  })
}
